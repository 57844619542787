<template>
	<div class="home-page" style="padding:20px;">
		<a-spin :spinning="loading">
			<div >
				<div>
					<home-count :shop="shop" :order="order" :elderly="elderly" :ServicePersonnel="ServicePersonnel" :access="access"></home-count>
				</div>

				<div class="mt30">
					<home-ranking :search="search" @updateData="updateData" :project_data="project_data" :appointment_data="appointment_data"></home-ranking>
				</div>

			</div>

			<div v-if="renewLoading">
				<re-new :visible="renewLoading" @cancel="cancelRenew" @ok="okRenew"></re-new>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import homeCount from './components/Count.vue';
	import homeRanking from './components/Ranking.vue';
	import reNew from './components/index/modal/renew.vue';
	export default {
		components: {
			homeCount,
			homeRanking,
			reNew,
		},
		data() {
			return {
				loading:false,
				status: 1,
				renewLoading:false,
        ifExpire:false,
				shop:{},
				search:{
					balance_time_type:1,
					integral_time_type:1,
				},
				order:{
					total_num:0,
					today_num:0,
					month_num:0
				},
				elderly:{
					total_num:0,
					today_num:0,
					month_num:0
				},
				ServicePersonnel:{
					total_num:0,
					today_num:0,
					month_num:0
				},
        access:{
          total_num:0,
          today_num:0,
          month_num:0
        },
				project_data:[],
				appointment_data:[],
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				if(this.loading===true) return;
				this.loading=true;
				this.$http.api('platform/home/index',{
					project_time_type:this.search.project_time_type,
					appointment_time_type:this.search.appointment_time_type
				}).then(res=>{
          this.shop = res.shop;
					this.elderly=res.elderly;
					this.order=res.order;
					this.access=res.access;
					this.ServicePersonnel=res.ServicePersonnel;
					this.project_data=res.project_data;
					this.appointment_data=res.appointment_data;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			updateData(value){
				console.log(value);
				this.search=value;
				this.loaddata();
			},

			renewAct(){
				this.renewLoading=true;
			},
			cancelRenew(){
				this.renewLoading=false;
			},
			okRenew(){
				this.renewLoading=false;
				this.loaddata();
			},
		},
	}
</script>

<style>
	.home-page {
		min-width: 1280px;
	}

	.home-page-header {
		height: 60px;
		width: 100%;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 0 20px;
		display: flex;
		align-items: center;
	}

	.home-notice-icon {
		width: 24px;
		height: 24px;
		border-radius: 12px;
		text-align: center;
		line-height: 24px;
		background: rgba(255, 153, 0, 0.1);
		color: rgba(255, 153, 0, 1);
	}

	.kefu-box {
		width: 220px;
		height: 260px;
		background: #FFFFFF;
		border-radius: 8px;
		overflow: hidden;
		line-height: 1;
	}

	.kefu-box-top {
		width: 100%;
		height: 188px;
		padding: 20px 20px 16px 20px;
	}

	.kefu-box-bottom {
		width: 100%;
		height: 72px;
		position: relative;
	}

	.kefu-box-bottom .main {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 72px;
		padding: 15px 20px;
	}

	.kefu-box-bottom .bg {
		width: 100%;
		height: 82px;
	}

	.qrcode-box {
		width: 220px;
		background: #FFFFFF;
		border-radius: 8px;
		overflow: hidden;
		padding: 20px;
		line-height: 1;
	}

	.mendian-status-box {
		width: 220px;
		background: #FFFFFF;
		border-radius: 8px;
		overflow: hidden;
		padding: 20px;
		line-height: 1;
	}
</style>
