<template>
    <div>
        <div class="flex">
            <div style="width: calc(100% - 220px);">
                <a-row :gutter="30">
                    <a-col :span="8">
                        <div class="count-box">
                            <div class="flex alcenter">
                                <i class="iconfont icondata01 ft32" style="color:#33A0FD;"></i>
                                <span class="ft18 cl-main ftw600 ml15">工单数 </span>
                            </div>
                            <div class="mt24 text-center ft16 cl-info">
                                累计
                            </div>
                            <div class="mt16 text-center cl-black ft30 ftw600" @click="goToPages('/appointment/order')">{{ order.total_num }}</div>
                            <div class="flex" style="margin-top: 42px;">
                                <div class="col2 text-center">
                                    <div class="ft16 cl-info">今日新增</div>
                                    <div class="ft18 mt8 ftw600 cl-black">{{ order.today_num }}</div>
                                </div>
                                <div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
                                    <div class="ft16 cl-info">本月新增</div>
                                    <div class="ft18 mt8 ftw600 cl-black">{{ order.month_num }}</div>
                                </div>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="count-box">
                            <div class="flex alcenter">
                                <i class="iconfont icondata02 ft32" style="color:#00C3A0;"></i>
                                <span class="ft18 cl-main ftw600 ml15">长者用户数</span>
                            </div>
                            <div class="mt24 text-center ft16 cl-info">
                                累计
                            </div>
                            <div class="mt16 text-center cl-black ft30 ftw600" @click="goToPages('/member/elder')">{{ elderly.total_num }}</div>
                            <div class="flex" style="margin-top: 42px;">
                                <div class="col2 text-center">
                                    <div class="ft16 cl-info">今日新增</div>
                                    <div class="ft18 mt8 ftw600 cl-black">{{ elderly.today_num }}</div>
                                </div>
                                <div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
                                    <div class="ft16 cl-info">本月新增</div>
                                    <div class="ft18 mt8 ftw600 cl-black">{{ elderly.month_num }}</div>
                                </div>
                            </div>
                        </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="count-box">
                            <div class="flex alcenter">
                                <i class="iconfont icondata031 ft32" style="color:#FEC75A;"></i>
                                <span class="ft18 cl-main ftw600 ml15">随访数</span>
                            </div>
                            <div class="mt24 text-center ft16 cl-info">
                                累计
                            </div>
                            <div class="mt16 text-center cl-black ft30 ftw600" @click="goToPages('/member/access')">{{access.total_num}}</div>
                            <div class="flex" style="margin-top: 42px;">
                                <div class="col2 text-center">
                                    <div class="ft16 cl-info">今日</div>
                                    <div class="ft18 mt8 ftw600 cl-black">{{access.today_num}}</div>
                                </div>
                                <div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
                                    <div class="ft16 cl-info">本月</div>
                                    <div class="ft18 mt8 ftw600 cl-black">{{access.month_num}}</div>
                                </div>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>

            <div>
              <div class="kefu-box ml20">
                <div class="kefu-box-top">
                  <div class="text-center mt16">
                    <img v-if="shop.site_logo != null" style="width: 100px; height: 100px; border:1px solid #F0F3F5;" :src="shop.site_logo" />
                  </div>
                  <div class="text-center ft14 cl-info mt12">
                    <div class="ft14 ftw600 cl-theme">{{shop.name}}</div>
                  </div>
                </div>
                <div class="kefu-box-bottom">
                  <img class="bg" src="../../../assets/image/woyaoyou_bg_phone@2x.png" />
                  <div class="main">
                    <div class="flex alcenter">
                      <i class="iconfont iconaddress ft20 cl-w"></i>
                      <div class="ft14 cl-w" style="line-height: 20px;">{{shop.address}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
			  site_num:{
					type:Number,
					default:0
				},
        shop:{
          type:Object,
          default:function(){
            return new Object
          }
        },
        order: {
            type: Object,
            default: {
                total_num: 0,
                today_num: 0,
                month_num: 0
            }
        },
        elderly: {
            type: Object,
            default: {
                total_num: 0,
                today_num: 0,
                month_num: 0
            }
        },
        ServicePersonnel: {
            type: Object,
            default: {
                total_num: 0,
                today_num: 0,
                month_num: 0
            }
        },
        access: {
          type: Object,
          default: {
            total_num: 0,
            today_num: 0,
            month_num: 0
          }
        }
    },
    data() {
        return {}
    },
    methods: {
        goToPages(uri){
            this.refreshPage(uri);
        }
    }

}
</script>

<style>
.ftw600{
    cursor: pointer;
}
.count-box {
    width: 100%;
    height: 260px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
}

.count-box div {
    line-height: 1;
}

.kefu-box {
    width: 220px;
    height: 260px;
    background: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
    line-height: 1;
}

.kefu-box-top {
    width: 100%;
    height: 188px;
    padding: 20px 20px 16px 20px;
}

.kefu-box-bottom {
    width: 100%;
    height: 72px;
    position: relative;
}

.kefu-box-bottom .main {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 72px;
    padding: 15px 20px;
}

.kefu-box-bottom .bg {
    width: 100%;
    height: 82px;
}
</style>
